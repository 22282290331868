.formLogin {
  background: #fff;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--secondary-color);
  padding: 25px;
  border-radius: 11px;
  min-width: 500px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  border-radius: 8px;
  padding: 20px;
  width: 200px; 
  height: 200px;
}
.input-container {
  position: relative;
}

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}




.formLogin > h2 {
  margin-bottom: 28px;
  color: var(--main-color);
  text-align: center;
}

.formLogin > div {
  margin-bottom: 15px;
}

.formLogin > div > div {
  position: relative;
}

.formLogin input[type="text"],
.formLogin input[type="email"],
.formLogin input[type="number"],
.formLogin input[type="password"]
{
  background: #fff;
  border-radius: 4px;
  outline: 0;
  border: 1px solid var(--secondary-color);
  box-shadow: var(--box-shadow);
  padding: 15px 32px;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.formLogin input[type="text"]:focus,
.formLogin input[type="email"]:focus,
.formLogin input[type="number"]:focus,
.formLogin input[type="password"]:focus
{
  border: 1px solid var(--main-color);
}

.unCompleted > input {
  border: 1px solid #ff0000 !important;
}

.completed > input {
  border: 1px solid #42ba96 !important;
  position: relative;
}

.unCompleted::after {
  content: "";
  background-image: url(../img/close.svg);
  position: absolute;
  width: 15px;
  height: 15px;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.completed::after {
  content: "";
  background-image: url(../img/check.svg);
  position: absolute;
  width: 15px;
  height: 15px;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.formLogin input[type="checkbox"] {
  position: relative;
  margin-right: 10px;
  width: 15px;
  height: 15px;
  border: 1px solid var(--main-color);
  appearance: none;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.formLogin input[type="checkbox"]:checked {
  background-color: var(--main-color-hover);
  background-image: url("../img/tik.svg");
}

.formLogin input[type="checkbox"]:focus {
  outline: none;
  border: 2px solid var(--main-color-hover);
}

.formLogin > div > div img {
  position: absolute;
  max-width: 20px;
  max-height: 20px;
  left: 7px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.formLogin ::placeholder {
  color: #bfbbbb;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}

.formLogin > div span.error {
  height: 10px;
  text-align: left;
  margin-top: 5px;
  width: fit-content;
  font-size: 0.7rem;
  color: red;
  line-height: 1px;
  padding: 9px 3px;
  border-radius: 3px;
  background-color: rgba(255, 0, 0, 0.175);
  display: inline-block;
}

.formLogin button[type="submit"] {
  display: block;
  text-align: center;
  width: 100%;
  border-radius: 8px;
  outline: 0;
  border: none;
  color: white;
  padding: 10px;
  margin: 15px 0;
  background: var(--main-color);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.formLogin button[type="submit"]:hover {
  background: var(--main-color-hover);
  cursor: pointer;
}

.terms {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 19px 0;
}

@media (max-width: 568px) {
  .formLogin {
    min-width: 300px;
    padding: 16px;
  }
  .formLogin input[type="text"],
  .formLoginQ input[type="email"],
  .formLogin input[type="password"]
  .formLogin input[type="number"]
   {
    padding: 10px 32px;
  }
}

.mobileIcon{
 color: var(--main-color);
 position: absolute;
  left: 10px; 
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  font-size: 18px; 
}
.formLogin > div > div {
  position: relative;
}



.formLogin input[type="number"] {
  background: #fff;
  border-radius: 4px;
  outline: 0;
  border: 1px solid var(--secondary-color);
  box-shadow: var(--box-shadow);
  padding: 15px 32px 15px 40px;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;  
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;

}

.mobileIcon {
  position: absolute;
  left: 10px; 
  top: 50%;
  transform: translateY(-50%);
  display: inline-block; 
}

.formLogin input[type="number"]::-webkit-inner-spin-button,
.formLogin input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0; 
}