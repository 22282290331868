.slider{
    display: flex;
    flex-direction: row;
    width: fit-content;
}

.slider-box{
    margin-bottom: 20px;
}
.slider h3{
    margin-bottom: 10px;
    width: fit-content;
}

.wrapper-class{
    display: flex;
    flex-direction: row;
   
}
.sort{
    margin-left: 400px;
    margin-top: 45px;
}
.sort-name{
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 20px;
}
.drop-down-list{
    list-style-type: none;
}
.sort ul{
    list-style-type: none;
}
.drop-down-list li{
    list-style: none;
}
.drop-down-icon{
    margin-right: 10px;
}
.drop-down-list{
    position: absolute;
}
.drop-down{
    top: 100%;
    left: -100px;
    background-color: white;
    border: 1px solid black;
    border-top: none;
    padding: 0;
    z-index: 9;
    margin-top: 5px;
}

.clear-button-div{
    margin-left: 400px;
    margin-top: 60px;
}

.clear-button{
    border: none;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;
}
@media (max-width: 768px) {
  .wrapper-class {
    flex-direction: column; 
  }

  .sort {
    margin-left: 0; 
    margin-top: 20px;
  }

  .clear-button-div {
    margin-left: 0; 
    margin-top: 20px;
  }

  .slider h3 {
    margin-bottom: 10px; 
  }

  .slider-box {
    margin-bottom: 10px;
  }

  .drop-down {
    left: 0; 
  }
  .slider h3{
    margin-top: 20px;
  }
}