.earphone-list {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 10px;
  margin-top: 5px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
}
.earphone-list::after {
  content: "";
  grid-column: span 5;
}

@media (max-width: 767px) {
  .earphone-list {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

