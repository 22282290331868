
.star-rating {
    display: flex;
    align-items: center;
  }
  
  .star-rating span {
    margin-right: 4px;
    font-size: 20px;
    color: #ffd700; 
  }
  